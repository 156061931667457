import styled from "styled-components";
import { NavLink as UnstyledNavLink } from "react-router-dom";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 87.5rem;
  padding: 16vh 0 10vh;
`;

export const InnerContainer = styled.div`
  margin: 0 10%;
`;

export const NavLink = styled(UnstyledNavLink).attrs({})`
  &.active {
    pointer-events: none;
    text-decoration: none;
    color: #000000;
  }
`;

export const Image = styled.img`
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  margin: 8vh 0 0;

  @media (min-width: 768px) {
    margin-right: 3rem;
  }
`;
