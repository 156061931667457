import React from "react";
import Heading from "../../components/Heading";
import Block from "../../components/Block";
import Credits from "../../components/Credits";
import Container from "../../components/Container";
import IntroImage from "./assets/intro.png";
import Challenge1Image from "./assets/challenge-1.png";
import Place1Image from "./assets/place-1.png";
import Place2Image from "./assets/place-2.png";
import Booking1Image from "./assets/booking-1.png";
import CalendarHost1Image from "./assets/calendarHost-1.png";
import CalendarHost2Image from "./assets/calendarHost-2.png";
import CalendarHost3Image from "./assets/calendarHost-3.png";
import ColorScheme1Image from "./assets/colorScheme-1.jpg";
import * as W from "../../styles";

const Nuento = () => {
  return (
    <Container>
      <Heading info="2019 · Nuento · Webapp">
        Booking Platform for Community Halls
      </Heading>

      <Block
        body={
          <>
            <W.Intro>
              Nuento is changing the way we arrange private events in Denmark.
              Making it easier to find the ideal room for your upcoming event.
              Moreover, making it more enjoyable to be a host by removing all
              the hassle.
            </W.Intro>
          </>
        }
        img={IntroImage}
      />
      <Block
        body={
          <>
            <p>
              In Denmark—community halls (‘places’) as we know them today—have
              existed for around a century. You will find a place in almost
              every small-medium sized city in Denmark.
            </p>

            <p>
              All kind of events are held in these places: birthday parties,
              weddings and much more. These places are deeply cultural and great
              for the local community. They spark life and provide a sense of
              unity. It’s important to keep these venues alive and running.
            </p>

            <p>
              Many places have a strained economy and rely on frequent bookings
              for outside private parties. This part should be easy both for the
              places and the people who book.
            </p>
          </>
        }
      />
      <Block
        heading="Challenge"
        body={
          <>
            <p>
              The places are well-known by the people living close by. Yet this
              is not always enough to keep a steady flow of bookings.
            </p>
            <p>
              Most places have limited presence online. At the same time they
              lack the budget to update their website every other year. For this
              reason the information might be obsolete and the booking process
              is old school. This means you will have to do it by a phone call
              or email. It has to be easier to find the ideal place for your
              upcoming event.
            </p>
          </>
        }
        img={Challenge1Image}
        caption="Illustration used to help visualize the concept of the platform."
      />

      <Block
        body={
          <>
            <p>
              We should also reduce the time that hosts* spend on
              administration. This will provide more time for the exciting
              tasks. So we have to make it more enjoyable to be a host by
              removing all the hassle.
            </p>
            <p>
              In this case study I will show you how we designed a better
              solution for everyone.
            </p>
            <p>
              <em>
                *In this case study the term ‘host’ will refer to the people who
                run the community halls (‘places’).
              </em>
            </p>
          </>
        }
      />

      <Block
        heading="Place"
        body={
          <>
            <p>
              We designed the place page in a manner so it’s eye-catching and
              clear. It had to be very easy to get an overview of the
              information.
            </p>
          </>
        }
        img={Place1Image}
        caption="Mobile layout of the place page."
      />

      <Block img={Place2Image} caption="Desktop layout of the place page." />

      <Block
        heading="Booking"
        body={
          <>
            <p>
              Starting off a booking is a critical step for the user. One thing
              we did to make the process smooth was to show the price in the
              calendar. This eliminates a scenario where you’d have to go back
              and forth to pick a date and check the price.
            </p>
          </>
        }
        img={Booking1Image}
        caption="Mobile layout of the initial steps in the booking flow."
      />

      <Block
        heading="Calendar – Host"
        body={
          <>
            <p>
              The calendar is a central part for the host. It's the place where
              they can view and manage the bookings.
            </p>
          </>
        }
        img={CalendarHost1Image}
        caption="Mobile layout of the host calendar in three different levels: year,
          month and schedule."
      />

      <Block
        body={
          <>
            <p>
              Most places have check-in and out times that make the booking
              overlap to the next day. In the month view (middle), it was
              important to visualize this for the host. This will give them a
              better idea when the bookings find place.
            </p>
            <p>
              We decided to make a calendar where you can scroll through the
              months for many reasons. One is that there is no limit to the
              height of the design for a single month. This turned out to be a
              problem for smaller devices when you had a lot of bookings.
            </p>
          </>
        }
        img={CalendarHost2Image}
        caption="Mobile layout of booking and date details."
      />

      <Block
        body={
          <>
            <p>
              With the booking details we saw the opportunity to make something
              beautiful—with a purpose. We wanted to give the user a sense of
              unfolding a booking. We did this by enlarging the blue bar into a
              completely blue scenery.
            </p>
          </>
        }
        img={CalendarHost3Image}
        caption="Desktop layout of the host calendar in the default view."
      />

      <Block
        headig="Color scheme"
        body={
          <>
            <p>
              We made more colors than we needed. We’ll admit that. These colors
              doesn’t only reflect what is in the current state of the product,
              but also what could come next.
            </p>
          </>
        }
        img={ColorScheme1Image}
      />

      <Block
        body={
          <>
            <p>
              In this project I was—and still am—responsible for the visual
              design, solving complex design problems and the overall creative
              direction. Furthermore I have the role as one of four co-founders.
            </p>
          </>
        }
      />

      <Credits
        client="Nuento"
        year={2019}
        roles={["Digital Designer", "UX Designer", "Illustrator"]}
      />
    </Container>
  );
};

export default Nuento;
