import styled from "styled-components";

export const Base = styled.div`
  max-width: 46rem;
  margin: 0 14% 0 10%;
`;

export const Info = styled.div`
  color: #434a54;
  font-family: "Space Mono", Courier, monospace;
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  @media (max-width: 415px) {
    font-size: 0.75rem;
  }
`;
