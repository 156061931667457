import styled from "styled-components";
import { ReactComponent as UnstyledLinkedInLogo } from "./assets/linkedin.svg";
import { ReactComponent as UnstyledDribbbleLogo } from "./assets/dribbble.svg";
import Link from "components/Link";

export const Container = styled.div`
  display: flex;
  max-width: 52em;
  padding: calc(2vh + 2rem) 0 calc(2vh + 2rem);

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Image = styled.img`
  width: 128px;
  height: 128px;
  flex-shrink: 0;
  margin-bottom: 2vh;

  @media (min-width: 768px) {
    margin-right: 3rem;
  }
`;

export const Content = styled.div`
  margin-bottom: 32vh;
  margin-right: 8%;

  & p:first-child {
    margin-top: 0;
    line-height: 1.75;
  }
`;

export const SocialIcons = styled.div`
  display: flex;
`;

export const SocialIcon = styled(Link).attrs({ as: "a", target: "_blank" })`
  margin-right: 1rem;
`;

export const LinkedInLogo = styled(UnstyledLinkedInLogo)`
  width: 32px;
  color: #000;
`;

export const DribbbleLogo = styled(UnstyledDribbbleLogo)`
  width: 32px;
  color: #000;
`;
