import styled from "styled-components";
import Link from "components/Link";

export const Base = styled(Link)`
  border-radius: 0.25rem;
  box-sizing: border-box;
  margin-top: calc(2rem + 4vh);
  width: 100%;
  text-decoration: none;
  @media (min-width: 769px) {
    width: calc(50% - 1rem);
  }
`;

export const Content = styled.div`
  border-radius: 4px;
  box-shadow: inset 0px 0px 0px 1px rgba(19, 21, 26, 0.12);
  padding-top: 75%;
  position: relative;
  width: 100%;
`;

export const Image = styled.img`
  border-radius: 4px;
  display: block;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

export const Info = styled.div`
  font-family: "Space Mono", Courier, monospace;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  text-transform: uppercase;
`;

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  width: 90%;
`;
