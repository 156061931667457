import React from "react";
import Heading from "../../components/Heading";
import Block from "../../components/Block";
import Credits from "../../components/Credits";
import Container from "../../components/Container";
import IntroImage from "./assets/intro.png";
import Challenge1Image from "./assets/challenge.png";
import Lectures1Image from "./assets/lectures-1.png";
import Lectures2Image from "./assets/lectures-2.png";
import Lectures3Image from "./assets/lectures-3.png";
import Cases1Image from "./assets/cases-1.png";
import Home1Image from "./assets/home-1.png";
import * as W from "../../styles";

const Learnsome = () => {
  return (
    <Container>
      <Heading info="2019 · Learnsome · Webapp">
        Bringing reality into the classroom
      </Heading>

      <Block
        body={
          <>
            <W.Intro>
              Learnsome is the new place where educational institutions meet
              businesses. It’s a place where universities get access to{" "}
              <i>lectures</i> from all sorts of businesses. And high schools get
              access to real world teaching material in the form of <i>cases</i>
              .
            </W.Intro>
          </>
        }
        img={IntroImage}
      />

      <Block
        heading="Challenge"
        body={
          <>
            <p>
              Design a platform where businesses can offer lectures to teachers
              from universities; and cases to teachers from high schools.
            </p>
            <p>
              It should be fast and efficient for the teachers to find a lecture
              or case that looks appealing. This means the design has to be
              clear while including all necessary information.
            </p>
          </>
        }
        img={Challenge1Image}
      />

      <Block
        heading="Lectures"
        body={
          <>
            <p>
              When a business provides a lecture on Learnsome, they provide
              reality for the students. This includes—but is not necessarily
              limited to—insights to real workflows and future job
              opportunities.
            </p>
          </>
        }
        img={Lectures1Image}
        caption="Search results for lectures."
      />

      <Block
        body={
          <>
            <p>
              A lecture consist of a title, a description, a few subcategories,
              a primary lecturer, assistant lecturers and a company.
            </p>
            <p>
              We made sure that you can start booking the lecture no matter
              where you are on the page. This was done with a sticky bottom bar
              that includes a ‘request booking’–button and general information.
            </p>
          </>
        }
        img={Lectures2Image}
        caption="The flow of booking a lecture. From lecture page to request booking to receipt."
      />

      <Block
        body={
          <>
            <p>
              The lecturers will instantly see when a booking has been
              requested. It will of course appear as a notification in the
              navigation. Furthermore it can also be found in a full overview at
              ‘My bookings’.
            </p>
            <p>
              One of the first things we wanted the lecturer to notice was the
              status of the booking. They can quickly decide to <i>Accept</i>,{" "}
              <i>Hold</i> or <i>Reject</i> the booking. Intuitively, a ‘Change
              status’ button will replace these buttons when a decision is made.
            </p>
          </>
        }
        img={Lectures3Image}
        caption="The journey of a booking in the lecturers point of view. From notification and booking overview to the booking page itself."
      />

      <Block
        heading="Cases"
        body={
          <>
            <p>
              On Learnsome, teachers will find modern business cases that are
              targeted their curriculum. The cases will support the education by
              combining theory and practice.
            </p>
            <p>
              When you arrive to the case page, the primary information is
              presented in a clear manner. The icons supports the categories and
              make each more recognisable when browsing through multiple
              results.
            </p>
          </>
        }
        img={Cases1Image}
        caption="Search results for cases followed by the layout of a case page."
      />

      <Block
        heading="Home"
        body={
          <>
            <p>
              The goal of the home page is to quickly provide the user with the
              content they’re looking for. This page is directed primarily at
              the teachers. So the lectures and cases are the top priority here.
              If you don’t want to dive in to one of these directly, you are
              presented with popular items from each category.
            </p>
            <p>
              If you are not interested in either, the remaining content
              provides you with reasons to why this could be interesting to you.
            </p>
          </>
        }
        img={Home1Image}
        caption="Layout of the home page."
      />

      <Credits
        agency="CompanYoung"
        client="Learnsome"
        year={2019}
        roles={["Digital Designer", "UX Designer", "Identity Designer"]}
      />
    </Container>
  );
};

export default Learnsome;
