import React from "react";
import * as S from "./styles";

const Heading = ({ children }) => (
  <S.Base>
    <S.Inner>{children}</S.Inner>
  </S.Base>
);

export default Heading;
