import React from "react";
import NuentoImage from "./assets/workImg-nuento.png";
import MaerskImage from "./assets/workImg-maersk.jpg";
import JustWallMeImage from "./assets/workImg-justWallMe.jpg";
import LearnsomeImage from "./assets/workImg-learnsome.png";
import Layout from "components/Layout";
import Heading from "./components/Heading";
import Work from "./components/Work";
import * as S from "./styles";

const HomePage = () => (
  <Layout>
    <Heading>Weaving beauty and function</Heading>

    <S.Works>
      <Work
        link="/work/nuento"
        info="2019 · Nuento · Webapp"
        title="Booking Platform for Community Halls"
        image={NuentoImage}
      />
      <Work
        link="/work/learnsome"
        info="2019 · Learnsome · Webapp"
        title="Bringing reality into the classroom"
        image={LearnsomeImage}
      />
      <Work
        link="/work/maersk"
        info="2019 · Maersk · Website"
        title="Frem i Verden – Recruitment Campaign"
        image={MaerskImage}
      />
      <Work
        link="/work/justwallme"
        info="2016 · Just Wall Me · Art Print Shop"
        title="Art Print Shop"
        image={JustWallMeImage}
      />
    </S.Works>
  </Layout>
);

export default HomePage;
