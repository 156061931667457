import React from "react";
import styled from "styled-components";
import { Link as RouterLinker } from "react-router-dom";

const UnstyledLink = ({ as: tag, ...props }) => {
  return React.createElement(tag === "link" ? RouterLinker : tag, props);
};

UnstyledLink.defaultProps = {
  as: "link"
};

const StyledLink = styled(UnstyledLink)`
  color: currentColor;
  text-decoration: underline;
`;

const Link = props => {
  const p = { ...props };
  if (props.target === "_blank") p.rel = "noopener noreferrer";

  return <StyledLink {...p} />;
};

export default Link;
