import React from "react";
import * as S from "./styles";

const Work = ({ link, title, image, info }) => {
  return (
    <S.Base to={link}>
      <S.Content>
        <S.Image src={image} alt={title} />
      </S.Content>
      <S.Info>{info}</S.Info>
      <S.Title>{title}</S.Title>
    </S.Base>
  );
};

export default Work;
