import styled from "styled-components";

export const Container = styled.div`
  margin: 0 auto;
  max-width: 87.5rem;
  padding: 10vh 0;
`;

export const InnerContainer = styled.div`
  margin: 0 10%;
`;

export const Works = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12rem;
`;
