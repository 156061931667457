import styled from "styled-components";

export const Base = styled.div`
  background: #f1f5f8;
`;

export const Inner = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 87.5rem;
  padding: 10rem 0;
  box-shadow: 1px 0px 0px 0px rgba(19, 21, 26, 0.12),
    -1px 0px 0px 0px rgba(19, 21, 26, 0.12);
  padding-bottom: 64vh;

  @media (max-width: 1460px) {
    max-width: 100%;
  }
`;
