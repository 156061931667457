import React from "react";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "components/ScrollToTop";
import HomePage from "./pages/Home";
import WorkPage from "./pages/Work";
import AboutPage from "./pages/About";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/work" component={WorkPage} />
            <Route path="/about" component={AboutPage} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
      <GlobalStyle />
    </>
  );
};

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 16px;
    overflow-y: scroll;
  }

  body {
    min-width: 320px;
    font-family: "Inter", Arial, Helvetica, sans-serif;
    line-height: 1.5;
    color: #000000;
    margin: 0;
  }

  svg {
    fill: currentColor;
  }

  p {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.125rem;
    }

    @media (max-width: 415px) {
      font-size: 1rem;
    }
  }

  h1 {
    font-size: 5rem;
    line-height: 1.1;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: 4rem;
    }

    @media (max-width: 415px) {
      font-size: 3rem;
    }

  }

  h2 {
    font-size: 4rem;
    line-height: 1.1;
    margin-bottom: 2rem;
    margin-top: 12rem;

    @media (max-width: 415px) {
      font-size: 3rem;
      margin-top: 6rem;
    }
    
  }

  h3 {
    font-size: 3rem;
    line-height: 1.1;
    margin-bottom: 0rem;
    margin-top: 4rem;

    @media (max-width: 415px) {
      font-size: 2rem;
      margin-top: 6rem;
    }
    
  }

  h4 {
    font-size: 2rem;
    margin-bottom: 0rem;
    margin-top: 4rem;

    @media (max-width: 415px) {
      font-size: 1.5rem;
      margin-top: 6rem;
    }
    
  }

  h5 {
    font-size: 1.5rem;
    line-height: 1.1;
    margin-bottom: 0rem;
    margin-top: 4rem;

    @media (max-width: 415px) {
      font-size: 1.25rem;
      margin-top: 6rem;
    }
    
  }
  ::selection {
    background: rgba(0, 255, 0, 0.32);
    color: #255c50;
  }
  
  iframe{
    width:1005;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  li a {
    display: block;
    text-align: center;
    margin-right: 1.25rem;
    text-decoration: none;
    font-size: 0.875rem;
    font-family: "Space Mono", Courier, monospace;
    text-transform: uppercase;
    text-decoration: underline;
  }

  a {
    color: #000000;
  }


`;

export default App;
