import React from "react";
import * as S from "./styles";

const Block = ({ heading, body, img, caption, video }) => (
  <div>
    <S.Base>
      {heading && <h2>{heading}</h2>}

      {body && <>{body}</>}
    </S.Base>

    {img &&
      (Array.isArray(img) ? (
        img.map(i => <S.Img key={i} src={i} list={true} alt="" />)
      ) : (
        <S.Img src={img} hasCaption={caption && caption.length > 0} alt="" />
      ))}

    {video &&
      (Array.isArray(video) ? (
        video.map(i => (
          <S.Video src={i} loop autoPlay playsInline list={true} alt="" />
        ))
      ) : (
        <S.Video
          loop
          autoPlay
          playsInline
          src={video}
          hasCaption={caption && caption.length > 0}
          alt=""
        />
      ))}

    {caption && <S.Caption>{caption}</S.Caption>}
  </div>
);

export default Block;
