import React from "react";
import * as S from "./styles";

const Heading = ({ tag, ...props }) => (
  <S.Base>{React.createElement(tag, props)}</S.Base>
);

Heading.defaultProps = {
  tag: "h1",
};

export default Heading;
