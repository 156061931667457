import React from "react";
import Layout from "components/Layout";

import * as S from "./styles";
import Link from "components/Link";

const AboutPage = () => (
  <Layout>
    <S.Container>
      <S.Content>
        {" "}
        <p>
          Mads Egmose
          <br />
          <Link as="a" href="mailto:madsegmose@gmail.com">
            madsegmose@gmail.com
          </Link>
          <br />
          <Link as="a" href="tel:+4527210921">
            +45 27 21 09 21
          </Link>
        </p>
        <br />
        <S.SocialIcons>
          <S.SocialIcon href="https://dribbble.com/madsegmose">
            <S.DribbbleLogo />
          </S.SocialIcon>
          <S.SocialIcon href="https://www.linkedin.com/in/madsegmose">
            <S.LinkedInLogo />
          </S.SocialIcon>
        </S.SocialIcons>
        <br />
        <p>
          I'm a visual designer who appreciates beauty and function. For the
          past decade it's been my profession to weave the two elements
          together—making useful digital experiences.
        </p>
      </S.Content>
    </S.Container>
  </Layout>
);

export default AboutPage;
