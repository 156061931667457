import React from "react";
import styled, { css } from "styled-components";

export const Base = styled.div`
  max-width: 36em;
  margin: 0 14% 0 10%;
`;

export const Img = styled(({ alt, list, hasCaption, ...props }) => (
  <img {...props} alt={alt || ""} />
))`
  width: 100%;
  display: block;

  ${({ list, hasCaption }) =>
    css`
      ${!list &&
        css`
          margin-top: 3.5rem;
        `}

      ${!hasCaption &&
        !list &&
        css`
          margin-bottom: 6rem;
        `}
    `}
`;

export const Video = styled(({ hasCaption, ...props }) => <video {...props} />)`
  width: 100%;
  display: block;

  ${({ list, hasCaption }) =>
    css`
      ${!list &&
        css`
          margin-top: 3.5rem;
        `}

      ${!hasCaption &&
        !list &&
        css`
          margin-bottom: 6rem;
        `}
    `}
`;

export const Caption = styled.div`
  color: #434a54;
  font-family: "Space Mono", Courier, monospace;
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin: 1rem 10% 6rem;
  line-height: 1.5;

  @media (max-width: 415px) {
    font-size: 0.75rem;
  }
`;
