import React from "react";
import MeImage from "./me.svg";
import * as S from "./styles";

const Layout = ({ children }) => (
  <S.Container>
    <S.InnerContainer>
      <ul>
        <li>
          <S.NavLink to="/" exact>
            Work
          </S.NavLink>
        </li>
        <li>
          <S.NavLink to="/about">About</S.NavLink>
        </li>
      </ul>
      <S.Image src={MeImage} alt="Mads Egmose" />
    </S.InnerContainer>
    <S.InnerContainer>{children}</S.InnerContainer>
  </S.Container>
);

export default Layout;
