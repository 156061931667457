import styled from "styled-components";

export const Work = styled.div`
  background: #f1f5f8;
`;

export const Container = styled.div`
  background-color: #ffffff;
  margin: 0 auto;
  max-width: 87.5rem;
  padding: 10rem 0;
  box-shadow: 1px 0px 0px 0px rgba(19, 21, 26, 0.12),
    -1px 0px 0px 0px rgba(19, 21, 26, 0.12);
  padding-bottom: 64vh;
`;

export const Headline = styled.div`
  max-width: 70rem;
  margin: 0 20% 0 10%;
`;

export const Intro = styled.h4`
  margin-top: 6rem;

  @media (max-width: 415px) {
    margin-top: calc(2rem + 4vh);
  }
`;
