import React from "react";
import * as S from "./styles";

const Heading = ({ info, children }) => (
  <S.Base>
    <S.Info>{info}</S.Info>
    <h1>{children}</h1>
  </S.Base>
);

export default Heading;
