import React from "react";
import Heading from "../../components/Heading";
import Block from "../../components/Block";
import Credits from "../../components/Credits";
import Container from "../../components/Container";
import IntroImage from "./assets/intro.jpg";
import Concept1Image from "./assets/concept-1.png";
import Concept2Image from "./assets/concept-2.png";
import Concept3Image from "./assets/concept-3.png";
import Concept4Image from "./assets/concept-4.png";
import Concept1Video from "./assets/conceptVideo-1.mp4";

import * as W from "../../styles";

const Maersk = () => {
  return (
    <Container>
      <Heading info="2019 · Maersk · Website">
        Frem i Verden – Recruitment Campaign
      </Heading>

      <Block
        body={
          <>
            <W.Intro>
              Maersk Line is the world's largest container shipping company.
              They operate in over 120 countries with over 600 ships in their
              fleet, so it’s safe to say they sail around the globe. <p />
              The goal of this campaign was to recruit more ship officers.
            </W.Intro>
          </>
        }
        img={IntroImage}
      />

      <Block
        heading="Concept"
        body={
          <>
            <p>
              We created a concept called ‘Frem i Verden’ which translates to
              ‘Forward Into the World’. This tagline represents three aspects of
              a ship officer. You're moving your career forward. You're
              physically moving forward on the globe on an Ultra Large Container
              Vessel (ULCV) as your workplace. And thirdly – you're moving
              forward on a personal level.
            </p>
            <p>
              We made it possible for the user to dive into the ULCV by making
              it interactive. We did this to encourage engagement. We let the
              user explore the education in a more interesting way.
            </p>
          </>
        }
      />

      <Block video={Concept1Video} />

      <Block
        body={
          <>
            <p>
              We wanted to push the maritime vibe to the next level by using the
              most grand pictures from Maersk. Moreover we used the dark blue
              color in big scale to represent the vast ocean.
            </p>
          </>
        }
      />

      <Block
        img={Concept1Image}
        caption="Mobile layout of the top view of various pages."
      />

      <Block img={[Concept2Image, Concept3Image, Concept4Image]} />

      <Credits
        agency="CompanYoung"
        client="Maersk"
        year={2019}
        roles={"Digital Designer"}
      />
    </Container>
  );
};

export default Maersk;
