import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import NuentoPage from "./pages/Nuento";
import LearnsomePage from "./pages/Learnsome";
import MaerskPage from "./pages/Maersk";
import JustWallMe from "./pages/JustWallMe";
import ReactGA from "react-ga";

ReactGA.initialize("UA-37595044-2");
ReactGA.pageview(window.location.pathname + window.location.search);

const Work = ({ match }) => {
  const { url } = match;

  return (
    <Switch>
      <Route path={`${url}/nuento`} component={NuentoPage} />
      <Route path={`${url}/learnsome`} component={LearnsomePage} />
      <Route path={`${url}/maersk`} component={MaerskPage} />
      <Route path={`${url}/justwallme`} component={JustWallMe} />
      <Redirect to="/" />
    </Switch>
  );
};

export default Work;
