import React from "react";
import * as S from "./styles";

const Credits = ({ client, year, roles, agency }) => (
  <S.Base>
    {agency && (
      <S.Item>
        <S.Title>Agency</S.Title>
        <S.Content>{agency}</S.Content>
      </S.Item>
    )}

    {client && (
      <S.Item>
        <S.Title>Client</S.Title>
        <S.Content>{client}</S.Content>
      </S.Item>
    )}

    {year && (
      <S.Item>
        <S.Title>Year</S.Title>
        <S.Content>{year}</S.Content>
      </S.Item>
    )}

    {roles && (
      <S.Item>
        <S.Title>Role</S.Title>
        <S.Content>
          {Array.isArray(roles)
            ? roles.map((role, i) => <S.Role key={i}>{role}</S.Role>)
            : roles}
        </S.Content>
      </S.Item>
    )}
  </S.Base>
);

export default Credits;
