import React from "react";
import Heading from "../../components/Heading";
import Block from "../../components/Block";
import Credits from "../../components/Credits";
import Container from "../../components/Container";
import IntroImage from "./assets/intro.jpg";
import Krystal1 from "./assets/krystal-1.jpg";
import Krystal2 from "./assets/krystal-2.jpg";
import TropicalParadise1 from "./assets/tropicalParadise-1.jpg";
import WebShop1 from "./assets/webShop-1.jpg";
import Misc1 from "./assets/misc-1.jpg";
import Misc2 from "./assets/misc-2.jpg";

import * as W from "../../styles";

const JustWallMe = () => {
  return (
    <Container>
      <Heading info="2016 · Just Wall Me · Art Print Shop">
        Art Print Shop
      </Heading>

      <Block
        body={
          <>
            <W.Intro>
              We wanted to be creative, have fun and make some money. So we
              started doing art prints. In this project you'll see the
              highlights of this journey.
            </W.Intro>
          </>
        }
        img={IntroImage}
      />

      <Block
        heading="Beautiful krystals"
        body={
          <>
            <p>
              We named this collection 'KRYSTAL' (Danish for 'Crystal'). For
              these prints we cherry-picked agates from around the world that
              had a simple and elegant aesthetic. We then photographed them
              ourselves and did hours of retouching afterwards.
            </p>
          </>
        }
      />

      <Block img={Krystal1} />

      <Block img={Krystal2} />

      <Block
        heading="Tropical Paradise"
        body={
          <>
            <p>
              This poster was made with a lot of photo manipulation. It’s based
              on photos from Ha Long Bay. We created the poster so it had an
              adventurous look with a lot of small details.
            </p>
          </>
        }
      />

      <Block img={TropicalParadise1} />

      <Block
        heading="Web shop"
        body={
          <>
            <p>
              We made the web shop simple so the products could stand out and be
              the main focus.
            </p>
          </>
        }
      />

      <Block img={WebShop1} />

      <Block img={[Misc1, Misc2]} />

      <Credits
        agency="Just Wall Me"
        client="Just Wall Me"
        year={2019}
        roles={["Digital Designer", "Creative Director", "Photographer"]}
      />
    </Container>
  );
};

export default JustWallMe;
