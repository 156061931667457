import styled from "styled-components";

export const Base = styled.div`
  margin: 8rem 10% 0 10%;
  display: flex;
  flex-wrap: wrap;
`;

export const Item = styled.div`
  width: 100%;

  @media (min-width: 769px) {
    width: calc(50% - 1rem);
  }
`;

export const Title = styled.div`
  font-size: 0.875rem;
  font-family: "Space Mono", Courier, monospace;
  color: #434a54;
  text-transform: uppercase;
  margin: 3rem 0 0.5rem;

  @media (max-width: 415px) {
    font-size: 0.75rem;
  }
`;

export const Content = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;
  color: #20252d;
`;
export const Role = styled.div`
  &:not(:first-child) {
    margin-top: 0.5rem;
  }
`;
